var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pt-10 sayfa-info"},[_c('sayfa-bilgi-info',{attrs:{"ana-sayfa-baslik":'Büro Listesi',"detay-sayfa-baslik":'Büro Detay',"ana-sayfa":true},on:{"click":_vm.oncekiSayfa}}),_c('sayfa-aciklama',{attrs:{"baslik":'Büro Listesi',"alt-baslik":'Yeni büro oluşturmanızı, bürolarınızı görmenizi ve değiştirmenizi sağlar.'}}),_c('v-col',{attrs:{"cols":"9","lg":"4"}},[_c('v-text-field',{staticClass:"buro-arama",attrs:{"label":"Ara","prepend-inner-icon":"mdi-magnify","hide-details":"","single-line":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3","lg":"4"}},[_c('form-dialog',{staticClass:"white--text",attrs:{"button-style":{
        borderRadius: '12px',
        height: '100%',
        width: _vm.$vuetify.breakpoint.mdAndDown ? '100%' : '35%',
      },"icon-color":'#fff',"is-big-button":true,"big_button_label":_vm.$vuetify.breakpoint.mdAndDown ? '' : 'Büro Ekle',"cover_all":true,"disabled":!_vm.$helper.isAuthorized('buro-ekle'),"tooltip":!_vm.$helper.isAuthorized('buro-ekle')
          ? 'Bu işlem için yetkiniz yok.'
          : '',"icon":"mdi-plus","big_button_color":"#5FB3F6","small":""},on:{"success":_vm.load},scopedSlots:_vm._u([{key:"default",fn:function({ formData, onSuccess, onClose }){return [_c('buro-form',{attrs:{"action":'/api/v1/buro'},on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"buro-card-style ma-auto",attrs:{"elevation":"0"}},[(_vm.items != null && _vm.$vuetify.breakpoint.mdAndDown)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Ad")]),_c('th',[_vm._v("İl")]),_c('th',[_vm._v("Vergi Dairesi")]),_c('th',[_vm._v("Vergi No")]),_c('th',[_vm._v("İşlemler")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(item.isim))])]),_c('td',[_vm._v(" "+_vm._s(item.adres.sehir ? item.adres.sehir.isim : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.vergi_dairesi)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.vergi_no)+" ")]),_c('td',{staticClass:"text-right simple-table-actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},!_vm.$helper.isAuthorized('buro-detay') ? on : null),[_c('v-btn',{attrs:{"disabled":!_vm.$helper.isAuthorized('buro-detay'),"color":"#040844","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',{attrs:{"color":"#959595"}},[_vm._v("mdi-chevron-right")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Bu işlem için yetkiniz yok.")])]),_c('delete-dialog',{attrs:{"icon-name":_vm.iconName,"action":`/api/v1/buro/` + item.id,"disabled":!_vm.$helper.isAuthorized('buro-sil'),"tooltip":!_vm.$helper.isAuthorized('buro-sil')
                      ? 'Bu işlem için yetkiniz yok.'
                      : ''},on:{"success":_vm.load}})],1)])}),0)]},proxy:true}],null,false,2902700278)}):_vm._e(),(_vm.items != null && !_vm.$vuetify.breakpoint.mdAndDown)?_c('v-data-table',{attrs:{"items":_vm.items,"search":_vm.search,"headers":_vm.headers,"dense":""},scopedSlots:_vm._u([{key:"item.ad",fn:function({ item }){return [_c('span',{staticClass:"seedetails",on:{"click":function($event){return _vm.detay(item)}}},[_vm._v(_vm._s(item.ad))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},!_vm.$helper.isAuthorized('buro-detay') ? on : null),[_c('v-btn',{attrs:{"disabled":!_vm.$helper.isAuthorized('buro-detay'),"color":"#040844","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',{attrs:{"color":"#959595"}},[_vm._v("mdi-chevron-right")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Bu işlem için yetkiniz yok.")])]),_c('delete-buro-dialog',{attrs:{"action":`/api/v1/buro/` + item.id,"itemId":item.id,"disabled":!_vm.$helper.isAuthorized('buro-sil'),"tooltip":!_vm.$helper.isAuthorized('buro-sil') ? 'Bu işlem için yetkiniz yok.' : ''},on:{"success":_vm.load,"editBuro":_vm.navigateToBuroInfo}})]}}],null,false,1112576676)}):(_vm.items==null)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }